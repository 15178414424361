import React, { useState, useEffect } from 'react';
import { X, Heart, MessageCircle } from 'lucide-react';

// Dummy data for profiles
const profiles = [
  { id: 1, name: "Alice", age: 28, image: "/api/placeholder/400/600" },
  { id: 2, name: "Bob", age: 32, image: "/api/placeholder/400/600" },
  { id: 3, name: "Charlie", age: 25, image: "/api/placeholder/400/600" },
  { id: 4, name: "Diana", age: 30, image: "/api/placeholder/400/600" },
  { id: 5, name: "Evan", age: 27, image: "/api/placeholder/400/600" },
];

const App = () => {
  const [currentProfileIndex, setCurrentProfileIndex] = useState(0);
  const [matches, setMatches] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [messages, setMessages] = useState({});

  const currentProfile = profiles[currentProfileIndex];

  const handleSwipe = (direction) => {
    if (direction === 'right') {
      setMatches([...matches, currentProfile]);
    }
    setCurrentProfileIndex((prevIndex) => (prevIndex + 1) % profiles.length);
  };

  const openChat = (match) => {
    setSelectedMatch(match);
    setShowChat(true);
    if (!messages[match.id]) {
      setMessages({ ...messages, [match.id]: [] });
    }
  };

  const sendMessage = (text) => {
    const updatedMessages = {
      ...messages,
      [selectedMatch.id]: [...messages[selectedMatch.id], { text, sender: 'user' }]
    };
    setMessages(updatedMessages);

    // Simulate match reply
    setTimeout(() => {
      setMessages({
        ...updatedMessages,
        [selectedMatch.id]: [...updatedMessages[selectedMatch.id], { text: "hello to you", sender: 'match' }]
      });
    }, 1000);
  };

  return (
    <div className="h-screen flex flex-col">
      {!showChat ? (
        <>
          <div className="flex-1 relative">
            <img src={currentProfile.image} alt={currentProfile.name} className="w-full h-full object-cover" />
            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4 text-white">
              <h2 className="text-2xl font-bold">{currentProfile.name}, {currentProfile.age}</h2>
            </div>
          </div>
          <div className="flex justify-center space-x-4 p-4">
            <button onClick={() => handleSwipe('left')} className="bg-white p-4 rounded-full shadow-lg">
              <X className="w-8 h-8 text-red-500" />
            </button>
            <button onClick={() => handleSwipe('right')} className="bg-white p-4 rounded-full shadow-lg">
              <Heart className="w-8 h-8 text-green-500" />
            </button>
          </div>
          {matches.length > 0 && (
            <div className="p-4">
              <h3 className="text-xl font-semibold mb-2">Your Matches</h3>
              <div className="flex space-x-2 overflow-x-auto">
                {matches.map((match) => (
                  <div key={match.id} className="flex flex-col items-center" onClick={() => openChat(match)}>
                    <img src={match.image} alt={match.name} className="w-16 h-16 rounded-full object-cover" />
                    <span className="text-sm mt-1">{match.name}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="flex-1 flex flex-col">
          <div className="bg-gray-100 p-4 flex items-center">
            <button onClick={() => setShowChat(false)} className="mr-4">
              <X className="w-6 h-6" />
            </button>
            <h2 className="text-xl font-semibold">{selectedMatch.name}</h2>
          </div>
          <div className="flex-1 overflow-y-auto p-4 space-y-2">
            {messages[selectedMatch.id]?.map((message, index) => (
              <div key={index} className={`${message.sender === 'user' ? 'text-right' : 'text-left'}`}>
                <span className={`inline-block p-2 rounded-lg ${message.sender === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
                  {message.text}
                </span>
              </div>
            ))}
          </div>
          <div className="p-4 border-t">
            <form onSubmit={(e) => {
              e.preventDefault();
              const input = e.target.elements.message;
              sendMessage(input.value);
              input.value = '';
            }}>
              <div className="flex">
                <input
                  type="text"
                  name="message"
                  placeholder="Type a message..."
                  className="flex-1 border rounded-l-lg p-2"
                />
                <button type="submit" className="bg-blue-500 text-white p-2 rounded-r-lg">
                  <MessageCircle className="w-6 h-6" />
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;